interface SectionDividerProps {
    title?: string;
    subtitle?: string;
}

const SectionDivider = ({ title, subtitle }: SectionDividerProps) => {
    return (
        <div className='sectionDivider heading'>
            {title && <h3 className='heading__title'>{title}</h3>}
            {subtitle && <p className='heading__subtitle'>{subtitle}</p>}
        </div>
    );
};

export default SectionDivider;
