/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';

import { SectionDivider } from '../../components/ui';
import { global } from '../../constants/globals';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useWindowSize } from '../../hooks/useWindowSize';
import Flickity from './flk.js';

type ShowOrHide = 'show' | 'hide';

// get website name from url, eg. 'gemporia'
const urlHost = window.location.host.split('.')[1];
const localStorageName = 'recentlyViewedProducts' + urlHost;
const flickityOptions = {
  pageDots: false,
  cellAlign: 'left',
  contain: true,
  groupCells: true,
  prevNextButtons: true,
  lazyload: 1,
};

export default function RecentlyViewed() {
  const [products, setProducts] = useLocalStorage<
    { sku: string; slug: string }[]
  >(localStorageName, []);
  const [displayClearAllButton, setDisplayClearAllButton] = useState(true);
  const size = useWindowSize();

  const removeIndividualItem = (
    event: React.MouseEvent<HTMLDivElement>,
    itemSku: string
  ) => {
    event.preventDefault();
    const productsObjectCopy = products.filter((itm) => {
      return itm.sku !== itemSku;
    });
    setProducts(productsObjectCopy);
  };

  const removeAllItems = () => {
    setProducts([]);
    window.localStorage.removeItem(localStorageName);
  };

  const showOrHideFlickityButtons = useCallback((val: ShowOrHide) => {
    const buttons = document.querySelectorAll<HTMLElement>(
      '#recentlyViewed .flickity-prev-next-button'
    );

    buttons.forEach((button) => {
      if (val === 'show') {
        button.style.display = 'block';
      } else {
        button.style.display = 'none';
      }
    });
  }, []);

  useEffect(() => {
    if (products) {
      if (size.width! < 768) {
        if (products.length > 2) {
          setDisplayClearAllButton(true);
          showOrHideFlickityButtons('show');
        } else {
          setDisplayClearAllButton(false);
          showOrHideFlickityButtons('hide');
        }
      } else if (size.width! < 1024) {
        if (products.length > 4) {
          setDisplayClearAllButton(true);
          showOrHideFlickityButtons('show');
        } else {
          setDisplayClearAllButton(false);
          showOrHideFlickityButtons('hide');
        }
      } else {
        if (products.length > 5) {
          setDisplayClearAllButton(true);
          showOrHideFlickityButtons('show');
        } else {
          setDisplayClearAllButton(false);
          showOrHideFlickityButtons('hide');
        }
      }
    }
  }, [products, showOrHideFlickityButtons, size]);

  const skuMetaTag = document.getElementsByClassName(
    'sku-cell'
  )[0] as HTMLElement;

  // if there are no saved products, display nothing
  if (!products || products.length === 0) return null;

  // if there is just 1 product saved and it's the same
  // as the current one displaying, display nothing
  if (products && products.length === 1 && skuMetaTag) return null;

  return (
    <div className='recentlyViewed'>
      <SectionDivider
        title='Recently Viewed'
        subtitle='Changed your mind? Take another look'
      />
      <Flickity
        className={'product-slider'}
        options={flickityOptions}>
        {skuMetaTag
          ? products
              .filter((itm) => {
                const sku = skuMetaTag.innerText.toLowerCase();
                return itm.sku !== sku;
              })
              .map((product, _) => (
                <a
                  className='slider__container slide'
                  href={`/${global.langCult}/product/${product.slug}/${product.sku}/`}
                  key={product.sku}>
                  <img
                    className='slide__image'
                    src={`${global.productImageURL}200/${product.sku}.jpg`}
                    data-flickity-lazyload={`${global.productImageURL}200/${product.sku}.jpg`}
                  />
                  <div
                    className='old-button__close'
                    onClick={(event) =>
                      removeIndividualItem(event, product.sku)
                    }
                    role='button'
                    aria-roledescription='delete item from recently viewed history'
                  />
                </a>
              ))
          : products.map((product, _) => (
              <a
                className='slider__container slide'
                href={`/${global.langCult}/product/${product.slug}/${product.sku}/`}
                key={product.sku}>
                <img
                  className='slide__image'
                  src={`${global.productImageURL}200/${product.sku}.jpg`}
                  data-flickity-lazyload={`${global.productImageURL}200/${product.sku}.jpg`}
                />
                <div
                  className='old-button__close'
                  onClick={(event) => removeIndividualItem(event, product.sku)}
                  role='button'
                  aria-roledescription='delete item from recently viewed history'
                />
              </a>
            ))}
      </Flickity>
      {displayClearAllButton && (
        <div
          className='old-button__clearAll'
          onClick={removeAllItems}
          role='button'
          aria-roledescription='delete all items from recently viewed history'>
          Clear All
        </div>
      )}
    </div>
  );
}
